<template>
    <div>
    </div>
</template>

<script>

import Helpers from "@/utilities/Helpers";

import Vue from "vue";

export default {
  
    mixins: [Helpers],

    props: {
        nextUrl: {
            type: String,
            default: "/"
        }
    },

    methods: {
        async validarLogin() {

            let idUsuario = localStorage.getItem("idUsuario");

            if (idUsuario === null || idUsuario === undefined) {
                idUsuario = 0;
            }

            const usuarioResult = await this.$store.dispatch("logInModule/UserById", idUsuario);

            if (usuarioResult.corTema === 1)  { 
                //dark
                this.$vuetify.theme.dark = true;
            }
            else { 
                // light
                this.$vuetify.theme.dark = false;
            }

            return usuarioResult;
        },

        async validateToken() {
            let routePath = "";

            let token = this.nextUrl.split('=')[1]

            if (token == null || token == undefined || token == "") {
                routePath = this.nextUrl
            }
            else {
                try {
                    let validToken = await Vue.prototype.$spTechApi.get(`login/validate-token/${token}`,
                    {
                        headers: {
                            "content-type": "application/json"
                        }
                    });

                    if (validToken.status === 200) {
                        this.$router.push({ name: "recuperarSenha", params: { token: token } });
                    }
                    else {
                        routePath = this.nextUrl
                    }
                }
                catch {
                    this.showToast("error", "Aviso!", "Token Inválido!");
                    this.$router.push({ path: "/login" });
                }
            }

            return routePath;
        },

        async validateCustomerToken(tipo) {
            let routePath = "";

            let guid = this.nextUrl.split('=')[1]

            if (guid == null || guid == undefined || guid == "") {
                routePath = this.nextUrl
            }
            else {
                try {

                    let url = `pedido/validate-guid/${guid}`;

                    if (tipo == "O") {
                        url = `orcamento/validate-guid/${guid}`;
                    }

                    let response = await Vue.prototype.$spTechApi.get(url,
                    {
                        headers: {
                            "content-type": "application/json"
                        }
                    });

                    if (response.status === 200) {
                        this.$router.push({ name: "trackerPage", params: { guid, tipo } });
                    }
                    else {
                        routePath = this.nextUrl
                    }
                }
                catch {
                    this.showToast("error", "Warning!", "Token inválido!");
                    this.$router.push({ path: "/login" });
                }
            }

            return routePath;
        }
    },

    async created() {

        this.showLoading();

        if (location.protocol !== 'https:' && process.env.NODE_ENV !== 'development') {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }
        else {
            
            var routePath = this.nextUrl
            
            if (routePath.includes('validate-token')) {
                routePath = await this.validateToken();
            }
            else if (routePath.includes('validate-customer-ped-token')) {
                routePath = await this.validateCustomerToken("P");
            }
            else if (routePath.includes('validate-customer-orc-token')) {
                routePath = await this.validateCustomerToken("O");
            }
            else
            {
                let loginValido = await this.validarLogin();
                
                if (loginValido === null || loginValido === undefined)
                    loginValido = false;

                if (loginValido === false) {
                    this.$router.push("/logout");
                } 
                else {

                    if (routePath.includes('redirectTo')) {
                        routePath = this.nextUrl.split('=')

                        if (routePath.length > 0) {
                            routePath = routePath[1]
                        }
                        else {
                            routePath = this.nextUrl
                        }
                    }

                    routePath = routePath.toString().replaceAll("%2F", "/")

                    Vue.prototype.getRouter.push({
                        path: routePath
                    });
                }
            }
        }

        this.hideLoading();
    }
};
</script>